// Generated by Framer (92b2253)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JkdbuRFRT", "j2SQ9hyjG"];

const serializationHash = "framer-4RTST"

const variantClassNames = {j2SQ9hyjG: "framer-v-x0l4rv", JkdbuRFRT: "framer-v-cxq8qb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Apple store": "JkdbuRFRT", "Play store": "j2SQ9hyjG"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "JkdbuRFRT"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "JkdbuRFRT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://apps.apple.com/nl/app/433-the-home-of-football/id1601630546"} {...addPropertyOverrides({j2SQ9hyjG: {href: "https://play.google.com/store/apps/details?id=com.football433&pcampaignid=web_share", openInNewTab: true}}, baseVariant, gestureVariant)}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 159, intrinsicWidth: 537, pixelHeight: 159, pixelWidth: 537, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/2DwjgYC96pwXLAT1mB7QsbP9w.png", srcSet: "https://framerusercontent.com/images/2DwjgYC96pwXLAT1mB7QsbP9w.png?scale-down-to=512 512w,https://framerusercontent.com/images/2DwjgYC96pwXLAT1mB7QsbP9w.png 537w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-cxq8qb", className, classNames)} framer-1t1ad9j`} data-framer-name={"Apple store"} layoutDependency={layoutDependency} layoutId={"JkdbuRFRT"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({j2SQ9hyjG: {"data-framer-name": "Play store", background: {alt: "", fit: "stretch", intrinsicHeight: 159, intrinsicWidth: 537, pixelHeight: 170, pixelWidth: 564, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/0HO3sh3YHbRwCTqNRCcFxIDUVks.png", srcSet: "https://framerusercontent.com/images/0HO3sh3YHbRwCTqNRCcFxIDUVks.png?scale-down-to=512 512w,https://framerusercontent.com/images/0HO3sh3YHbRwCTqNRCcFxIDUVks.png 564w"}}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4RTST.framer-1t1ad9j, .framer-4RTST .framer-1t1ad9j { display: block; }", ".framer-4RTST.framer-cxq8qb { height: 52px; position: relative; text-decoration: none; width: 174px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 174
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"j2SQ9hyjG":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergXCkkInWM: React.ComponentType<Props> = withCSS(Component, css, "framer-4RTST") as typeof Component;
export default FramergXCkkInWM;

FramergXCkkInWM.displayName = "Download the app";

FramergXCkkInWM.defaultProps = {height: 52, width: 174};

addPropertyControls(FramergXCkkInWM, {variant: {options: ["JkdbuRFRT", "j2SQ9hyjG"], optionTitles: ["Apple store", "Play store"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramergXCkkInWM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})